<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12 bread-crumbs">
        <router-link :to="'/decompte/fer/list'">
          Décompte >
        </router-link>
        <router-link :to="`/decompte/fer/detail/${$route.params.id}`">
          Décompte n°{{$route.params.id}} >
        </router-link>
        <span>Modification travaux</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            MODIFICATION DE DECOMPTE
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="form_holder max_700">
        <div class="row">
          <Notif :notif="notif" />
        </div>
        <form class="accordion" 
              id="accordionExample">
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingOne">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                <th>Source du décompte</th>
              </tr>
            </thead>
            <div id="collapseOne"
                 class="collapse show"
                 aria-labelledby="headingOne"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Numéro de marché <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="marches.donnees"
                              :reduce="marche => marche.id"
                              label="numero_marche"
                              ref="marche"
                              class="v-select"
                              @input="searchMarche(form.marche)"
                              v-model="form.marche"
                              placeholder="Choisir un marché">
                      <span slot="no-options"
                            @click="$refs.marche.open = false"
                            class="text-danger">
                        Aucun marché trouvé
                      </span>
                    </v-select>
                    <span v-if="form.errors().has('marche') && affiche.marche !== 'marche'"
                          v-text="form.errors().get('marche')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <template v-if="marche.activite">
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Ordonnancement <sup><span class="req-star">*</span></sup>
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <v-select :options="marche.ordonnancements"
                                :reduce="ordonnancement =>ordonnancement.id"
                                label="annee_ordonnancement"
                                ref="marche"
                                class="v-select"
                                @input="searchSituation(form.marche,form.ordonnancement)"
                                v-model="form.ordonnancement"
                                placeholder="Choisir un ordonnancement">
                        <span slot="no-options"
                              @click="$refs.marche.open = false"
                              class="text-danger">
                          Aucun ordonnancement trouvé
                        </span>
                      </v-select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Programme</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.activite.rubrique.programme.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Rubrique</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.activite.rubrique.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Activité</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.activite.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Type d'entretien</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.type_entretien }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Département</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <span v-for="(departement,dekey) in marche.departement"
                            :key="dekey">{{ departement.libelle }}</span> <br>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Objet des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.objet }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'approbation</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_approbation_marche }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Lot</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.lot }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Entreprise</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.entreprise.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Référence ordre de service de démarrage</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.reference_ordre_service_demarrage }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date ordre de service de demarrage</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_ordre_service_demarrage }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date de début</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_debut }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date de fin</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_fin }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Délai d'exécution (mois)</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.delai_execution_marche }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date démarrage effectif</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_demarrage_effectif }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date de fin des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_fin_travaux }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Référence ordre de service d'arrêt</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.reference_ordre_service_arret }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date ordre de service d'arrêt</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_ordre_service_arret }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Référence ordre de service de reprise des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.reference_ordre_service_reprise_travaux }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date ordre de service de reprise des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_ordre_service_reprise_travaux }}</div>
                  </div>
                </template>
              
              </div>
            </div>
          </table>

          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingTwo">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo">
                <th>Information du décompte</th>
              </tr>
            </thead>
            <div id="collapseTwo"
                 class="collapse"
                 aria-labelledby="headingTwo"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Situation financière du marché</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    {{ form.situation_financiere_marche.toLocaleString() }} FCFA
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Situation financière de l'ordonnancement</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    {{ form.situation_financiere_ordonnancement.toLocaleString() }} FCFA
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Numéro décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-on:click="removeSpanError('numero_decompte')"
                           v-model="form.numero_decompte" />
                    <span v-if="form.errors().has('numero_decompte') && affiche.numero_decompte !== 'numero_decompte'"
                          v-text="form.errors().get('numero_decompte')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence Ordre de service de prorogation de délai</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="form.reference_ordre_service_prorogation_delai" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date d'ordre de service de prorogation de délai</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_ordre_service_prorogation_delai" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence Ordre Service de modification d'itinéraire</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="form.reference_ordre_service_modification_itineraire" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de l'Ordre Service de modification d'itinéraire</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_service_modification_itineraire" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Désignation de l'assurance chantier</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           v-model="form.libelle_assurance_chantier"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Montant de l'assurance chantier</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           @input="addSpace('montant_assurance_chantier')"
                           v-model="montant_assurance_chantier"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Date d'établissement de l'assurance chantier</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_assurance_chantier')"
                           v-model="form.date_assurance_chantier" />
                    <span v-if="form.errors().has('date_assurance_chantier') && affiche.date_assurance_chantier !== 'date_assurance_chantier'"
                          v-text="form.errors().get('date_assurance_chantier')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Expiration de l'assurance chantier</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.expiration_assurance_chantier_date" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Désignation du cautionnement AD</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           v-model="form.libelle_cautionnement_ad"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Montant du cautionnement AD</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           @input="addSpace('montant_cautionnement_ad')"
                           v-model="montant_cautionnement_ad"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'établissement du cautionnement AD</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date" 
                           class="form-control"
                           v-model="form.date_etablissement_cautionnement_ad"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Expiration de la caution AD</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="[
                        {value:'date',libelle:'Date'},
                        {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                        {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                        {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                      ]"
                      :reduce="expiration => expiration.value"
                      label="libelle"
                      ref="expiration_ad"
                      id="expiration_ad"
                      v-model="expiration_ad"
                      @input="checkExpiration('expiration_caution_ad_texte','expiration_ad')"
                      placeholder="Choisir un type d'expiration"
                    >
                      <span slot="no-options"
                            @click="$refs.programme.open = false"
                            class="text-danger">
                        Aucune type
                      </span>
                    </v-select>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-if="expiration_ad=='date'"
                           v-model="form.expiration_caution_ad_date" />
                    <input type="text"
                           class="form-control"
                           v-if="expiration_ad!='date'"
                           v-model="form.expiration_caution_ad_texte" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Désignation du cautionnement RG</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="form.libelle_cautionnement_rg" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Montant du cautionnement RG</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           @input="addSpace('montant_cautionnement_rg')"
                           v-model="montant_cautionnement_rg"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date d'établissement du cautionnement RG</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_etablissement_cautionnement_rg" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Expiration de la caution RG</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="[
                        {value:'date',libelle:'Date'},
                        {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                        {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                        {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                      ]"
                      :reduce="expiration => expiration.value"
                      label="libelle"
                      ref="expiration_rg"
                      id="expiration_rg"
                      @input="checkExpiration('expiration_caution_rg_texte','expiration_rg')"
                      v-model="expiration_rg"
                      placeholder="Choisir un type d'expiration"
                    >
                      <span slot="no-options"
                            @click="$refs.programme.open = false"
                            class="text-danger">
                        Aucune type
                      </span>
                    </v-select>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-if="expiration_rg=='date'"
                           v-model="form.expiration_caution_rg_date" />
                    <input type="text"
                           class="form-control"
                           v-if="expiration_rg=='texte'"
                           v-model="form.expiration_caution_rg_texte" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Référence Facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-on:click="removeSpanError('reference_facture')"
                           v-model="form.reference_facture" />
                    <span v-if="form.errors().has('reference_facture') && affiche.reference_facture !== 'reference_facture'"
                          v-text="form.errors().get('reference_facture')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Montant Facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           @input="addSpace('montant_decompte')"
                           v-on:click="removeSpanError('montant_decompte')"
                           v-model="montant_decompte" />
                    <span
                      v-if="form.errors().has('montant_decompte') && affiche.montant_decompte !== 'montant_decompte'"
                      v-text="form.errors().get('montant_decompte')"
                      class="errorMsg"
                    >
                    </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date d'établissement de la facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_etablissement_decompte" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Taux d'exécution physique</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    
                    <input type="text"
                           class="form-control"
                           v-model="form.taux_execution_physique" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de visa de l'Attachement/constat des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_visa_attachement" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de l'Attachement/constat des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_attachement_constat_travaux" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de validation de l'Attachement/constat des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_validation_attachement" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Désignation du cautionnement definitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           v-model="form.libelle_cautionnement_definitif"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Montant du cautionnement définitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           @input="addSpace('montant_cautionnement_definitif')"
                           v-model="montant_cautionnement_definitif"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'établissement du cautionnement définitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date" 
                           class="form-control"
                           v-model="form.date_etablissement_cautionnement_definitif"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'expiration du cautionnement définitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="[
                        {value:'date',libelle:'Date'},
                        {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                        {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                        {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                      ]"
                      :reduce="expiration => expiration.value"
                      label="libelle"
                      ref="expiration_definitif"
                      id="expiration_definitif"
                      @input="checkExpiration('expiration_cautionnement_definitif_texte','expiration_definitif')"
                      v-model="expiration_definitif"
                      placeholder="Choisir un type d'expiration"
                    >
                      <span slot="no-options"
                            @click="$refs.programme.open = false"
                            class="text-danger">
                        Aucune type
                      </span>
                    </v-select>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-if="expiration_definitif=='date'"
                           v-model="form.expiration_cautionnement_definitif_date" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Montant Décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           @input="addSpace('montant_facture_decompte')"
                           v-on:click="removeSpanError('montant_facture_decompte')"
                           v-model="montant_facture_decompte" />
                    <span
                      v-if="form.errors().has('montant_facture_decompte') && affiche.montant_facture_decompte !== 'montant_facture_decompte'"
                      v-text="form.errors().get('montant_facture_decompte')"
                      class="errorMsg"
                    >
                    </span>
                  </div>
                </div>
                
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Date décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_facture')"
                           v-model="form.date_facture" />
                    <span v-if="form.errors().has('date_facture') && affiche.date_facture !== 'date_facture'"
                          v-text="form.errors().get('date_facture')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Date de validation par le DR</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_facture')"
                           v-model="form.date_visa_dr_decompte" />
                    <span v-if="form.errors().has('date_facture') && affiche.date_facture !== 'date_facture'"
                          v-text="form.errors().get('date_facture')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Date de validation par le DMC</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_facture')"
                           v-model="form.date_visa_dmc_decompte" />
                    <span v-if="form.errors().has('date_facture') && affiche.date_facture !== 'date_facture'"
                          v-text="form.errors().get('date_facture')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Date de validation par le DGA</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_facture')"
                           v-model="form.date_visa_dga_decompte" />
                    <span v-if="form.errors().has('date_facture') && affiche.date_facture !== 'date_facture'"
                          v-text="form.errors().get('date_facture')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Date de fin des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_fin_travaux')"
                           v-model="form.date_fin_travaux" />
                    <span v-if="form.errors().has('date_fin_travaux') && affiche.date_fin_travaux !== 'date_fin_travaux'"
                          v-text="form.errors().get('date_fin_travaux')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Date Etablissement Procès verbal reception travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_etablissement_proces_verbal_reception_travaux')"
                           v-model="form.date_etablissement_proces_verbal_reception_travaux" />
                    <span v-if="form.errors().has('date_etablissement_proces_verbal_reception_travaux') 
                            && affiche.date_etablissement_proces_verbal_reception_travaux !== 'date_etablissement_proces_verbal_reception_travaux'"
                          v-text="form.errors().get('date_etablissement_proces_verbal_reception_travaux')"
                          class="errorMsg"></span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Date d'établissement du certificat de service fait</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_certificat_service_fait')"
                           v-model="form.date_certificat_service_fait"/>
                    <span v-if="form.errors().has('date_certificat_service_fait') && affiche.date_certificat_service_fait !== 'date_certificat_service_fait'"
                          v-text="form.errors().get('date_certificat_service_fait')"
                          class="errorMsg"></span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Référence du certificat de service fait</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('reference_certificat_service_fait')"
                           v-model="form.reference_certificat_service_fait"/>
                    <span v-if="form.errors().has('reference_certificat_service_fait') && affiche.reference_certificat_service_fait !== 'reference_certificat_service_fait'"
                          v-text="form.errors().get('reference_certificat_service_fait')"
                          class="errorMsg"></span>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <!--  -->
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingThree">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree">
                <th>Fichiers à télécharger</th>
              </tr>
            </thead>
            <div id="collapseThree"
                 class="collapse"
                 aria-labelledby="headingThree"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Caution d’avance de démarrage</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.caution_avance_demarrage }}
                      </label>
                      <input
                        type="file"
                        name="caution_avance_demarrage"
                        class="custom-file-input"
                        id="caution_avance_demarrage"
                        ref="caution_avance_demarrage"
                        v-on:click="removeSpanError('caution_avance_demarrage')"
                        v-on:change="handleFileUpload('caution_avance_demarrage')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span
                        v-if="form.errors().has('caution_avance_demarrage') && affiche.caution_avance_demarrage !== 'caution_avance_demarrage'"
                        v-text="form.errors().get('caution_avance_demarrage')"
                        class="errorMsg"
                      >
                      </span>
                      <br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.caution_avance_demarrage" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Cautionnement définitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.cautionnement_definitif }}
                      </label>
                      <input
                        type="file"
                        name="cautionnement_definitif"
                        class="custom-file-input"
                        id="cautionnement_definitif"
                        ref="cautionnement_definitif"
                        v-on:click="removeSpanError('cautionnement_definitif')"
                        v-on:change="handleFileUpload('cautionnement_definitif')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span
                        v-if="form.errors().has('cautionnement_definitif') && affiche.cautionnement_definitif !== 'cautionnement_definitif'"
                        v-text="form.errors().get('cautionnement_definitif')"
                        class="errorMsg"
                      >
                      </span
                      ><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.cautionnement_definitif" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req"
                             for="">Retenue de garantie (5%) </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.retenue_garantie }}
                      </label>
                      <input
                        type="file"
                        name="reference_arc"
                        class="custom-file-input"
                        id="reference_arc"
                        ref="retenue_garantie"
                        v-on:click="removeSpanError('retenue_garantie')"
                        v-on:change="handleFileUpload('retenue_garantie')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('retenue_garantie') && affiche.retenue_garantie !== 'retenue_garantie'"
                            v-text="form.errors().get('retenue_garantie')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.retenue_garantie" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Proces verbal de reception des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.proces_verbal_reception_travaux }}
                      </label>
                      <input
                        type="file"
                        name="proces_verbal_reception_travaux"
                        class="custom-file-input"
                        id="proces_verbal_reception_travaux"
                        ref="proces_verbal_reception_travaux"
                        v-on:click="removeSpanError('proces_verbal_reception_travaux')"
                        v-on:change="handleFileUpload('proces_verbal_reception_travaux')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span
                        v-if="form.errors().has('proces_verbal_reception_travaux') && affiche.proces_verbal_reception_travaux !== 'proces_verbal_reception_travaux'"
                        v-text="form.errors().get('proces_verbal_reception_travaux')"
                        class="errorMsg"
                      >
                      </span
                      ><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.proces_verbal_reception_travaux" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.decompte }}
                      </label>
                      <input
                        type="file"
                        name="decompte"
                        class="custom-file-input"
                        id="decompte"
                        ref="decompte"
                        v-on:click="removeSpanError('decompte')"
                        v-on:change="handleFileUpload('decompte')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('decompte') && affiche.decompte !== 'decompte'"
                            v-text="form.errors().get('decompte')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.decompte" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Attachement/constat des travaux </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.attachement }}
                      </label>
                      <input
                        type="file"
                        name="attachement"
                        class="custom-file-input"
                        id="attachement"
                        ref="attachement"
                        v-on:click="removeSpanError('attachement')"
                        v-on:change="handleFileUpload('attachement')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('attachement') && affiche.attachement !== 'attachement'"
                            v-text="form.errors().get('attachement')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.attachement" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.facture }}
                      </label>
                      <input
                        type="file"
                        name="facture"
                        class="custom-file-input"
                        id="facture"
                        ref="facture"
                        v-on:click="removeSpanError('facture')"
                        v-on:change="handleFileUpload('facture')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('facture') && affiche.facture !== 'facture'"
                            v-text="form.errors().get('facture')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.facture" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Ordre de service de prorogation de délai</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.ordre_service_prorogation_delai }}
                      </label>
                      <input
                        type="file"
                        name="ordre_service_prorogation_delai"
                        class="custom-file-input"
                        id="ordre_service_prorogation_delai"
                        ref="ordre_service_prorogation_delai"
                        v-on:click="removeSpanError('ordre_service_prorogation_delai')"
                        v-on:change="handleFileUpload('ordre_service_prorogation_delai')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('ordre_service_prorogation_delai') && affiche.ordre_service_prorogation_delai !== 'ordre_service_prorogation_delai'"
                            v-text="form.errors().get('ordre_service_prorogation_delai')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_prorogation_delai" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Ordre de service de modification d'itinéraire</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.ordre_service_modification_itineraire }}
                      </label>
                      <input
                        type="file"
                        name="ordre_service_modification_itineraire"
                        class="custom-file-input"
                        id="ordre_service_modification_itineraire"
                        ref="ordre_service_modification_itineraire"
                        v-on:click="removeSpanError('ordre_service_modification_itineraire')"
                        v-on:change="handleFileUpload('ordre_service_modification_itineraire')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('ordre_service_modification_itineraire') && affiche.ordre_service_modification_itineraire !== 'ordre_service_modification_itineraire'"
                            v-text="form.errors().get('ordre_service_modification_itineraire')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_modification_itineraire" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Assurances chantier</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.assurance_chantier }}
                      </label>
                      <input
                        type="file"
                        name="assurance_chantier"
                        class="custom-file-input"
                        id="assurance_chantier"
                        ref="assurance_chantier"
                        v-on:click="removeSpanError('assurance_chantier')"
                        v-on:change="handleFileUpload('assurance_chantier')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('assurance_chantier') && affiche.assurance_chantier !== 'assurance_chantier'"
                            v-text="form.errors().get('assurance_chantier')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.assurance_chantier" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Certificat de service fait</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.certificat_service_fait }}
                      </label>
                      <input
                        type="file"
                        name="certificat_service_fait"
                        class="custom-file-input"
                        id="certificat_service_fait"
                        ref="certificat_service_fait"
                        v-on:click="removeSpanError('certificat_service_fait')"
                        v-on:change="handleFileUpload('certificat_service_fait')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('certificat_service_fait') && affiche.certificat_service_fait !== 'certificat_service_fait'"
                            v-text="form.errors().get('certificat_service_fait')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.certificat_service_fait" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Observations</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <textarea class="form-control"
                              rows="3"
                              v-on:click="removeSpanError('observation')"
                              v-model="form.observation"> </textarea>
                    <span v-if="form.errors().has('observation') && affiche.observation !== 'observation'"
                          v-text="form.errors().get('observation')"
                          class="errorMsg"> </span>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <div class="form-row row mt-4">
            <div class="col-md-8 offset-sm-5">
              <div class="form-group">
                <div class="button_form">
                  <button type="button"
                          class="btn btn-f-blue"
                          @click="submit">
                    <i class="flaticon flaticon-floppy-disk"></i>
                    Envoyer
                  </button>
                  <!-- <button type="button"
                    class="btn btn-f-blue"
                    @click="saving()">
              <i class="flaticon flaticon-floppy-disk"></i>
              Enregistrer
            </button> -->
                  <button type="reset"
                          class="btn btn-f-blue"
                          data-toggle="modal"
                          data-target="#exampleModal">
                    <i class="flaticon flaticon-cancel"></i>
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
         
        </form>
        <!-- modal box -->
        <div class="modal fade"
             id="exampleModal"
             tabindex="-1"
             role="dialog"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog"
               role="document">
            <div class="modal-content">
              <div class="modal-header ">
                <h5 class="modal-title text-uppercase"
                    id="exampleModalLabel">Annulation</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
              </div>
              <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal">Non</button>
                <button type="button"
                        @click="goBack()"
                        data-dismiss="modal"
                        class="btn btn-primary">Oui</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./decompte.css";
button.btn-f-blue {
  /* width: 15%; */
  border-radius: none;
  font-size: 0.6em;
}
.listExtension {
  font-size: 12px;
}
div ::placeholder {
  color: rgba(220, 220, 220, 0.897);
}
.fileExtentions{
  color: #0d6efd;
} 
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.8em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.8em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: fit-content;
  }
}
</style>
<script>
import form from "vuejs-form"
import Loader from "@/components/shared/Spinner"
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"
import VSelect from "vue-select"
import 'vue-select/dist/vue-select.css'
export default {
  name: "UpdateDecompteFER",
  components: {
    Loader,
    Notif,
    VSelect
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    
    montant_ordonnancement: "",
    montant_facture_decompte: "",
    montant_decompte:"",
    montant_cautionnement_ad:"",
    montant_cautionnement_rg:"",
    montant_cautionnement_definitif:"",
    montant_assurance_chantier:"",
    expiration_ad:"date",
    expiration_rg:"date",
    expiration_definitif:"date",
    form: form({
      marche: "",
      numero_decompte: "",
      type_entretien: "",
      type_decompte: "decompte_travaux_fer",
      taux_execution_physique:"",
      objet_travaux: "",
      date_approbation: "",
      date_debut_execution: "",
      date_fin_execution: "",
      date_ordre_service: "",
      date_assurance_chantier: "",
      date_demarrage_effectif: "",
      date_etablissement_proces_verbal_reception_travaux:"",
      date_etablissement_cautionnement_definitif: "",
      date_expiration_cautionnement_definitif: "",
      date_facture: "",
      date_ordre_service_prorogation_delai:"",
      date_service_modification_itineraire:"",
      date_etablissement_cautionnement_rg:"",
      date_etablissement_decompte:"",
      date_attachement_constat_travaux:"",
      date_etablissement_cautionnement_ad: "",
      date_certificat_service_fait:"",
      expiration_assurance_chantier_date: "",
      expiration_assurance_chantier_texte: "",
      expiration_caution_ad_date: "",
      expiration_caution_ad_texte: "",
      expiration_caution_rg_date: "",
      expiration_caution_rg_texte: "",
      expiration_cautionnement_definitif_date: "",
      expiration_cautionnement_definitif_texte: "",
      retenue_garantie: "",
      caution_avance_demarrage: "",
      cautionnement_definitif: "",
      certificat_service_fait: "",
      date_etablissement_certificat_service_fait: "",
      reference_certificat_service_fait: "",
      proces_verbal_reception_travaux: "",
      decompte: "",
      attachement: "",
      situation_financiere_marche: "",
      situation_financiere_ordonnancement: "",
      facture: "",
      montant_facture_decompte: "",
      montant_ordonnancement: "",
      reference_facture: "",
      observation: "",
      ordre_service_prorogation_delai:"",
      ordre_service_modification_itineraire:"",
      assurance_chantier: "",
      reference_ordre_service_prorogation_delai:"",
      reference_ordre_service_modification_itineraire:"",
      montant_decompte:"",
      ordonnancement:"",
      // Champs à rajouter pour fiche d'analyse
      montant_assurance_chantier :"",
      montant_cautionnement_ad :"",
      montant_cautionnement_rg :"",
      montant_cautionnement_definitif :"",
      libelle_assurance_chantier :"",
      libelle_cautionnement_ad :"",
      libelle_cautionnement_rg :"",
      libelle_cautionnement_definitif :"",
      date_visa_attachement :"",
      date_validation_attachement :"",
      date_dressage_decompte :"",
      date_visa_dr_decompte :"",
      date_visa_dmc_decompte :"",
      date_visa_dga_decompte :"",
      /** fin **/
    }),
    marcheData: {
      limite: 100,
      avant: "MQ=="
    },
    gmarche: "",
    uploadfile: "",
    disabled: false,
    loading: {
      caution_avance_demarrage: false,
      retenue_garantie: false,
      cautionnement_definitif: false,
      proces_verbal_reception_travaux: false,
      decompte: false,
      attachement: false,
      situation_financiere_marche: false,
      ordre_service_prorogation_delai:false,
      ordre_service_modification_itineraire:false,
      certificat_service_fait: false,
      assurance_chantier: false,
      facture: false
    },
    inputFiles: {
      caution_avance_demarrage: "",
      retenue_garantie: "",
      cautionnement_definitif: "",
      proces_verbal_reception_travaux: "",
      decompte: "",
      attachement: "",
      situation_financiere_marche: "",
      ordre_service_prorogation_delai:"",
      ordre_service_modification_itineraire:"",
      certificat_service_fait: "",
      assurance_chantier: "",
      facture: ""
    },
    listExtensionFiles: [],
    affiche: {
      marche: "marche:true",
      // sous_traitant: "sous_traitant:true",
      numero_decompte: "numero_decompte:true",
      type_entretien: "type_entretien:true",
      type_decompte: "decompte_travaux_ageroute:true",
      objet_travaux: "objet_travaux:true",
      date_approbation: "date_approbation:true",
      montant_ordonnancement: "montant_ordonnancement:true",
      date_ordonnancement: "date_ordonnancement:true",
      date_debut_execution: "date_debut_execution:true",
      date_fin_execution: "date_fin_execution:true",
      date_ordre_service: "date_ordre_service:true",
      date_demarrage_effectif: "date_demarrage_effectif:true",
      caution_avance_demarrage: "caution_avance_demarrage:true",
      retenue_garantie: "retenue_garantie:true",
      cautionnement_definitif: "cautionnement_definitif:true",
      proces_verbal_reception_travaux: "proces_verbal_reception_travaux:true",
      decompte: "decompte:true",
      attachement: "attachement:true",
      situation_financiere_marche: "situation_financiere_marche:true",
      facture: "facture:true",
      montant_facture_decompte: "montant_facture_decompte:true",
      reference_facture: "reference_facture:true",
      date_facture: "date_facture:true",
      observation: "observation:true"
    }
  }),
  watch: {
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.loading[this.uploadfile] = false
        this.disabled = false
      }
    },
    situationMarche(){
      if (this.situationMarche) {
        this.form.situation_financiere_marche = Number(this.situationMarche.situation_financiere[0].marche)
        this.form.situation_financiere_ordonnancement = Number(this.situationMarche.situation_financiere[0].ordonnancement)
        console.log(this.situationMarche)
      }
    },
    filerrors() {
      if (this.filerrors) {
        this.loading[this.uploadfile] = false
        this.notif.message = this.filerrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.disabled = false
            this.notif.activated = ""
            this.$refs[this.uploadfile]=""
            this.form[this.uploadfile]=""
            this.inputFiles[this.uploadfile]=""
            this.setFileErors("")
          }.bind(this),
          3000
        )
      }
    },
    assignDecompte() {
      if (this.assignDecompte) {
        const assigndec = this.assignDecompte
      
        this.montant_facture_decompte=assigndec.montant_facture_decompte
        this.addSpace("montant_facture_decompte")
        this.montant_decompte=assigndec.montant_decompte
        this.addSpace("montant_decompte")
        this.montant_assurance_chantier=assigndec.montant_assurance_chantier
        this.addSpace("montant_assurance_chantier")
        this.montant_cautionnement_ad=assigndec.montant_cautionnement_ad
        this.addSpace("montant_cautionnement_ad")
        this.montant_cautionnement_rg=assigndec.montant_cautionnement_rg
        this.addSpace("montant_cautionnement_rg")
        this.montant_cautionnement_definitif=assigndec.montant_cautionnement_definitif
        this.addSpace("montant_cautionnement_definitif")

        this.form.id=assigndec.id
        this.form.marche=assigndec.marche.id
        this.searchMarche(assigndec.marche.id)
        this.searchSituation(assigndec.marche.id,assigndec.ordonnancement.id)
        this.form.numero_decompte=assigndec.numero_decompte
        this.form.type_entretien=assigndec.type_entretien
        this.form.type_decompte= "decompte_travaux_fer"
        this.form.objet_travaux=assigndec.objet_travaux
        this.form.date_approbation=assigndec.date_approbation
        this.form.date_ordonnancement=assigndec.date_ordonnancement
        this.form.date_debut_execution=assigndec.date_debut_execution
        this.form.date_fin_execution=assigndec.date_fin_execution
        this.form.date_ordre_service=assigndec.date_ordre_service
        this.form.date_assurance_chantier=assigndec.date_assurance_chantier
        this.form.date_demarrage_effectif=assigndec.date_demarrage_effectif
        this.form.date_proces_verbal_reception_travaux=assigndec.date_proces_verbal_reception_travaux
        this.form.date_etablissement_cautionnement_definitif=assigndec.date_etablissement_cautionnement_definitif
        this.form.date_facture=assigndec.date_facture
        this.form.date_ordre_service_prorogation_delai=assigndec.date_ordre_service_prorogation_delai
        this.form.date_service_modification_itineraire=assigndec.date_service_modification_itineraire
        this.form.date_etablissement_cautionnement_rg=assigndec.date_etablissement_cautionnement_rg
        this.form.date_etablissement_decompte=assigndec.date_etablissement_decompte
        this.form.date_attachement=assigndec.date_attachement
        this.form.date_etablissement_cautionnement_ad=assigndec.date_etablissement_cautionnement_ad
        this.form.date_certificat_service_fait=assigndec.date_certificat_service_fait
        this.form.expiration_assurance_chantier_date=assigndec.expiration_assurance_chantier_date
        this.form.expiration_caution_ad_date=assigndec.expiration_caution_ad_date
        this.form.expiration_caution_ad_texte=assigndec.expiration_caution_ad_texte
        this.form.expiration_caution_rg_date=assigndec.expiration_caution_rg_date
        this.form.expiration_caution_rg_texte=assigndec.expiration_caution_rg_texte
        this.form.expiration_caution_definitif_date=assigndec.expiration_caution_definitif_date
        this.form.expiration_caution_definitif_texte=assigndec.expiration_caution_definitif_texte
        this.form.retenue_garantie=assigndec.retenue_garantie
        this.form.taux_execution_physique=assigndec.taux_execution_physique
        this.form.caution_avance_demarrage=assigndec.caution_avance_demarrage
        this.form.cautionnement_definitif=assigndec.cautionnement_definitif
        this.form.date_etablissement_proces_verbal_reception_travaux=assigndec.date_etablissement_proces_verbal_reception_travaux
        this.form.proces_verbal_reception_travaux=assigndec.proces_verbal_reception_travaux
        this.form.decompte=assigndec.decompte
        this.form.attachement=assigndec.attachement
        this.form.situation_financiere_marche=assigndec.situation_financiere_marche
        this.form.facture=assigndec.facture
        this.form.reference_facture=assigndec.reference_facture
        this.form.observation=assigndec.observation
        this.form.ordre_service_prorogation_delai=assigndec.ordre_service_prorogation_delai
        this.form.ordre_service_modification_itineraire=assigndec.ordre_service_modification_itineraire
        this.form.assurance_chantier=assigndec.assurance_chantier
        this.form.reference_ordre_service_prorogation_delai=assigndec.reference_ordre_service_prorogation_delai
        this.form.reference_ordre_service_modification_itineraire=assigndec.reference_ordre_service_modification_itineraire
        this.form.ordonnancement=assigndec.ordonnancement.id
        // nouveau champs fiche analyse
        this.form.libelle_assurance_chantier = assigndec.libelle_assurance_chantier
        this.form.libelle_cautionnement_ad = assigndec.libelle_cautionnement_ad
        this.form.libelle_cautionnement_rg = assigndec.libelle_cautionnement_rg
        this.form.libelle_cautionnement_definitif = assigndec.libelle_cautionnement_definitif
        this.form.date_visa_attachement = assigndec.date_visa_attachement
        this.form.date_validation_attachement = assigndec.date_validation_attachement
        this.form.date_dressage_decompte = assigndec.date_dressage_decompte
        this.form.date_visa_dr_decompte = assigndec.date_visa_dr_decompte
        this.form.date_visa_dmc_decompte = assigndec.date_visa_dmc_decompte
        this.form.date_visa_dga_decompte = assigndec.date_visa_dga_decompte

        if (assigndec.caution_avance_demarrage) {
          this.getFileName("caution_avance_demarrage")
        }
        if (assigndec.retenue_garantie) { 
          this.getFileName("retenue_garantie")
        }
        if (assigndec.cautionnement_definitif) {
          this.getFileName("cautionnement_definitif")
        }
        if(assigndec.proces_verbal_reception_travaux){
          this.getFileName("proces_verbal_reception_travaux")
        }
        if (assigndec.decompte) {
          this.getFileName("decompte")
        }
        if(assigndec.attachement){
          this.getFileName("attachement")
        }
        if (assigndec.ordre_service_prorogation_delai) {
          this.getFileName("ordre_service_prorogation_delai")
        }
        
        if (assigndec.ordre_service_modification_itineraire) {
          this.getFileName("ordre_service_modification_itineraire")
        }
        if (assigndec.facture) {
          this.getFileName("facture")
        }
        if (assigndec.assurance_chantier) {
          this.getFileName("assurance_chantier")
        }
        if (assigndec.certificat_service_fait) {
          this.getFileName("certificat_service_fait")
        }
      }
    },
    successdecompte() {
      if (this.successdecompte) {
        this.notif.message = this.successdecompte
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decompterrors) {
        this.notif.message = this.decompterrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    successdecompteP() {
      if (this.successdecompteP) {
        this.notif.message = this.successdecompteP
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decompterrorsP) {
        this.notif.message = this.decompterrorsP
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    marche(){
      if (this.marche) {        
        var nouveau_departement=""
        for (let index = 0; index < this.marche.departement.length; index++) {
          if (index<this.marche.departement.length && index+1<this.marche.departement.length) {
            nouveau_departement+=this.marche.departement[index].libelle+" - "    
          }else{
            nouveau_departement+=this.marche.departement[index].libelle    
          }
        }
        this.marche.departement=nouveau_departement
        
      }
    },
  },
  created() {
    this.listExtensionFiles = utils.formaFichierAccepte
    this.ListMarches()
    this.getDecompte(this.$route.params.id)
    // this.getSousTraitants()
  },
  computed: {
    ...mapGetters(["marches","marche", "errors", "successdecompte", "filerrors", "soustraitants", "files", "decompterrors", "successdecompteP", "decompterrorsP","assignDecompte","situationMarche","msgSituationMarche"])
  },
  methods: {
    ...mapActions(["groupMarches", "saveDecomptes", "saveDP", "getSousTraitants", "ListMarches", "saveFile","getDecompte","updateDecomptes","situationMarcheDecompte","getMarche"]),
    ...mapMutations(["setSuccess", "setErors","setFileErors", "setSuccessP","setDecompte", "setErrorP","setSituationMarche","setMsgFailSituationMarche"]),
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      if (!this.inputFiles[filename].includes(" ")) {
        const extension = this.inputFiles[filename].split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.loading[filename] = true
          this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs[filename].files[0])
        } else {
          this.inputFiles[filename] = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.inputFiles[filename] = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      
    },
    searchSituation(id_marche,id_ordonnancement) {
      this.setSituationMarche("")
      this.situationMarcheDecompte({id_marche:id_marche,id_ordonnancement:id_ordonnancement})
    },
    searchMarche(id) {
      this.getMarche(id)
      this.removeSpanError('marche')
    },
    recreate(value,key){
      var splinter = value.split(" ")
      this.form[key] = ""
      for (let index = 0; index < splinter.length; index++) {
        this.form[key] += splinter[index]
      }
    },
    submit() {
      this.form.validate()
      if (this.assignDecompte.statut == "DEMANDE_INFORMATION") {
        this.form.statut = "EN_ATTENTE"
      }
      this.recreate(this.form.montant_ordonnancement,'montant_ordonnancement')
      this.recreate(this.form.montant_facture_decompte,'montant_facture_decompte')
      if (this.form.montant_facture_decompte != this.assignDecompte.montant_facture_decompte) {
        if (Number(this.form.montant_facture_decompte) <= this.form.situation_financiere_marche) {
          this.updateDecomptes({ data: this.form.data, pid: this.form.data.id })  
        }else{
          this.notif.message = "Le montant de la facture/decompte ne doit pas excéder la situation financière du  marché"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            3000
          )
        }
      }else{
        this.updateDecomptes({ data: this.form.data, pid: this.form.data.id })  
      }
    },
    addSpace(key) {
      var chaine = ""
      if (this[key]!=null) {
        var splinter = this[key].split("")
        if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
          for (let index = 0; index < splinter.length; index++) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
            chaine += splinter[index]
          }
          this.form[key] = this[key]
          if (this[key].length >= 4) {
            // check if there's a space
            if (this[key].includes(" ")) {
              var trimer = chaine.split(" ")
              chaine = ""
              for (let index = 0; index < trimer.length; index++) {
                chaine += trimer[index]
              }
              chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            } else {
              chaine = ""
              chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
          }
        }

        this[key] = chaine
      }
      
    },
    saving() {
      this.recreate(this.form.montant_ordonnancement, 'montant_ordonnancement')
      this.recreate(this.form.montant_facture_decompte, 'montant_facture_decompte')
      this.saveDP(this.form.data)
    },
    goBack() {
      this.$router.push({ name: "decompte" })
    },
    removeSpanError(element) {
      for (let affichekey in this.affiche) {
        const valeur = this.affiche[affichekey].split(":")[0]
        if (valeur === element) {
          this.affiche[affichekey] = valeur
        }
      }
    },
    /**
     * Creation des espaces pour les extentions de fichiers
     * @returns {string}
     * @constructor
     */
    CreateEspaceBetweenFileExtentions(){
      return utils.formaFichierAccepteToString()
    },
    getFileName(key){
      this.inputFiles[key] = this.form[key].split("/")
      this.inputFiles[key] = this.inputFiles[key][this.inputFiles[key].length-1]
      this[key] = this.inputFiles[key]
    },
  }
}
</script>
